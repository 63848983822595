import * as React from "react";
import Layout from "../components/Layout";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect-4.svg";
import TunisiaJobs from "../assets/img/tunisia-jobs-5-10-1.png";
import TunisiaJobs50 from "../assets/img/tunisiaJobs5_0.png";
import TunisiaJobs51 from "../assets/img/tunisiaJobs5_1.png";
import TunisiaJobs52 from "../assets/img/tunisiaJobs5_2.png";

const TextileAndApparel = () => (
  <Layout>
    <main role="main">
      <div className="container-fluid">
        <div className="region region-banner">
          <div
            id="block-textileapparel"
            className="block block-block-content block-block-contente74ece04-6c27-4f84-8419-a7b46fe22555"
          >
            <div className="intern-banner">
              <div className="overlay">
                <img src={BannerEffect} alt="" />
              </div>
              <img src={TunisiaJobs} alt="" />
              <div className="caption">
                <h1 className="animate__animated animate__fadeInUp animate__slow">
                  <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                    Textile and Apparel
                  </div>
                </h1>
                <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                  <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>
                      Supporting the textile industry's{" "}
                      <span>competitiveness</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="region region-content">
          <div data-drupal-messages-fallback className="hidden" />
          <div
            id="block-jobs-front-content"
            className="block block-system block-system-main-block"
          >
            <article
              data-history-node-id={46}
              role="article"
              about="/en/textile-and-apparel"
              className="node node--type-activities node--promoted node--view-mode-full"
            >
              <div className="node__content">
                <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                  <div>
                    <section className="sector-vision">
                      <div className="container">
                        <div className="row">
                          <div className="card-wrapper">
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              <div className="inner-card">
                                <h3>The Sector</h3>
                                <p>
                                  Textiles and Apparel is one of Tunisia’s most
                                  important sectors, accounting for more than $
                                  1 billion in exports, making up just over 7%
                                  of the country’s total exports, and employing
                                  approximately 160,000 workers nationwide. The
                                  sector is labor-intensive and employs
                                  predominantly women, making it an important
                                  sector for growing women’s participation in
                                  the workforce.
                                </p>
                              </div>
                            </div>
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay
                            >
                              <div className="inner-card">
                                <h3>JOBS’ Vision and Strategy</h3>
                                <p>
                                  JOBS is supporting SMEs working in the textile
                                  and apparel sector to help them grow by (1)
                                  enhancing technical and soft skills of
                                  Tunisian operators and managers, (2) helping
                                  companies transition from sub-contracting
                                  business models to producing finished,
                                  higher-end products for international buyers,
                                  (3) helping SMEs to export and enter new
                                  markets, and (4) supporting textile companies
                                  to become attractive, near-shore alternatives
                                  to Asian competitors, especially in the
                                  post-COVID recovery period. JOBS works with
                                  individual textile and apparel companies, as
                                  well as textile groups, value chains, and
                                  regional clusters.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="our-work">
                      <div className="brick brick--type--our-work-workforce brick--id--70 eck-entity container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Our Work
                          </div>
                        </h2>
                        <div className="aos-animate" data-aos="fade-up">
                          <p>
                            To date, JOBS has partnered with 124 companies in
                            the sector, with more than 30% of our partners
                            located in underserved regions and more than 40% of
                            our partners based in the Sahel (Sousse, Monastir,
                            and Mahdia), Tunisia’s textile hub with the largest
                            concentration of textile manufacturers. More than
                            60% of our efforts in the sector aim to help
                            partners source candidates for vacant positions,
                            followed by pre-employment trainings, lean
                            manufacturing, and certifications.
                          </p>
                        </div>
                        <div className="work-card-wrapper">
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={TunisiaJobs50} alt="" />
                            <div className="about-work">
                              <h5>
                                Developing a better and more effective workforce
                              </h5>
                              <p>
                                JOBS is helping companies to implement
                                pre-employment trainings for new recruits in
                                sewing skills or specific skills related to
                                technically complex items with a better added
                                value (e.g., coats and jackets, workwear,
                                technical clothing, and delicate lingerie). JOBS
                                is also supporting companies to develop a better
                                working environment by providing soft skills and
                                management trainings for managers.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <img src={TunisiaJobs51} alt="" />
                            <div className="about-work">
                              <h5>
                                Supporting apparel manufacturers to improve
                                quality and efficiency
                              </h5>
                              <p>
                                JOBS supports SMEs that make value added and/or
                                specialized products like coated textiles,
                                medical apparel, leather goods and medical masks
                                or gowns made from non-woven fabrics. These
                                businesses require more specialized labor and
                                equipment. Our support includes implementing
                                lean manufacturing tools, optimizing production
                                processes, improving supply chain management,
                                and complying with industry-specific standards.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={TunisiaJobs52} alt="" />
                            <div className="about-work">
                              <h5>Helping companies increase product value</h5>
                              <p>
                                Most apparel manufacturers in Tunisia source to
                                international manufacturers with well-known
                                brands. Our work aims to help companies optimize
                                costs, thereby increasing profits, permitting
                                firms to grow and hire more workers, through
                                improvements in product quality, efficiency, and
                                compliance with international standards and to
                                optimize workflows to reduce production delays.
                                Many Tunisian manufacturers perform low
                                value-added tasks like cutting, washing, dyeing,
                                and sewing ready-to-wear apparel. In these
                                cases, JOBS helps these companies to implement
                                international standards and secure new clients
                                that will permit our partners to sell directly
                                to end customers.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="impact aos-animate" data-aos="fade-up">
                      <div className="brick brick--type--impact brick--id--71 eck-entity container">
                        <span className="title-left">
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Impact To Date
                          </div>
                        </span>
                        <h2></h2>
                        <ul className="pl-0">
                          <li>
                            <span>
                              Provided assistance to 130 technical textile and
                              apparel companies.
                            </span>
                          </li>
                          <li>
                            <span>
                              Helped create approximately 6,947 jobs in partner
                              firms.
                            </span>
                          </li>
                          <li>
                            <span>
                              Facilitated sales increases exceeding 131 million
                              TND.
                            </span>
                          </li>
                          <li>
                            <span>
                              Launched a pilot collaboration with the Tunisian
                              Textile Federation (FTTH) in Nabeul to assist 6
                              FTTH affiliated companies to implement training
                              for middle managers in team management,
                              recruitment, and pre-employment trainings for new
                              staff, leading to 300 jobs created. The success of
                              this action has led the FTTH to duplicate the
                              training in other regions, such as Sfax.
                            </span>
                          </li>
                          <li>
                            <span>
                              Awarded COVID-19 emergency grants to textile
                              companies, Consomed, Medika Hitex, and Medicoset,
                              to produce critical goods to fight the pandemic,
                              including medical masks, sterile gowns, and other
                              personal protective wear.
                            </span>
                          </li>
                          <li>
                            <span>
                              Awarded COVID-19 recovery grants benefitting 31
                              textile and apparel- manufacturing companies to
                              sustain more than 867 jobs and aid their recovery
                              from the economic impact of the pandemic.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);
export default TextileAndApparel;
